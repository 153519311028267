@font-face {
  font-family: "gensen-rounded-r";
  src: url("../public/GenSenRounded-R.ttc") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gensen-rounded-b";
  src: url("../public/GenSenRounded-B.ttc") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gensen-rounded-h";
  src: url("../public/GenSenRounded-H.ttc") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: "dmsans-vf";
  src: url("../public/DMSans-VariableFont.ttc") format("truetype");
  font-weight: 100 700;
  font-style: normal;
} */

/* Cms Fonts */
@font-face {
  font-family: "futura";
  src: url("../public/futura.ttc") format(truetype);
}
@font-face {
  font-family: "futura-h1";
  src: url("../public/futura_h1.ttf") format(truetype);
}
@font-face {
  font-family: "futura-bk";
  src: url("../public/futura_bk.ttf") format(truetype);
}
@font-face {
  font-family: "futura-bold";
  src: url("../public/futura_bold.ttf") format(truetype);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-template {
    @apply rounded-full bg-violet-500  px-5 py-2 font-semibold  text-white shadow-md hover:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-400 focus:ring-opacity-75;
  }

  .btn {
    @apply transform cursor-pointer rounded-xl bg-red-500 px-3 py-5 text-center text-xl font-semibold text-white duration-200 ease-linear;
  }
  .btn:hover {
    @apply scale-110 border  bg-white text-red-500;
  }

  .menu-item-hover-border {
    @apply border-l-2 border-r-2 border-t-2 border-white;
  }

  .confirm-modal-btn {
    @apply hover-scale-sm text-dark mt-6 flex h-12 w-full items-center justify-center rounded-xl bg-[#16e6ed] text-sm font-semibold focus:border-0 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent;
  }
}

@layer utilities {
  /* Text */
  .text-primary {
    @apply text-[var(--primary-color)];
  }
  .text-secondary {
    @apply border-[var(--secondary-color)];
  }
  .text-dark {
    @apply text-[#030623];
  }
  .text-grey {
    @apply text-[#717071];
  }
  .text-black {
    @apply text-[#1F1E30];
  }
  .text-lightgrey {
    @apply text-[#DCDFE6];
  }
  .text-placeholder {
    @apply text-[#717071B3];
  }

  /* Font */
  .font-dm-sans {
    font-family: "DM Sans", sans-serif;
  }
  .font-gensen-regular {
    font-family: gensen-rounded-r;
  }
  /* 700 */
  .font-gensen-bold {
    font-family: gensen-rounded-b;
  }
  /* 900 */
  .font-gensen-heavy {
    font-family: gensen-rounded-H;
  }
  /* Cms Fonts */
  .futura {
    font-family: futura;
  }
  .futura-h1 {
    font-family: futura-h1;
  }
  .futura-bk {
    font-family: futura-bk;
  }
  .futura-bold {
    font-family: futura-bold;
  }

  /* Border */
  .border-primary {
    @apply border-[var(--primary-color)];
  }
  .border-secondary {
    @apply border-[var(--secondary-color)];
  }
  .border-lightgrey {
    @apply border-[#DCDFE6];
  }

  /* Box shadow */
  .shadow-floating-label {
    box-shadow: 0px 6px 24px -6px rgba(3, 6, 35, 0.1);
  }

  /* Background */
  .bg-primary {
    @apply bg-[var(--primary-color)];
  }
  .bg-secondary {
    @apply bg-[var(--secondary-color)];
  }
  .bg-primary-light {
    @apply bg-[#00B3C8] bg-opacity-[0.08];
  }
  .bg-hover-color-main {
    @apply bg-[var(--hover-color)];
  }
  .bg-cms-grey {
    @apply bg-[#F9FAFB];
  }

  /* Layout and Container */
  .layout {
    @apply w-full p-9;
  }

  /* Hover Scale */
  .hover-scale {
    @apply duration-200 ease-linear;
  }
  .hover-scale:hover {
    @apply scale-105;
  }
  .hover-scale-sm {
    @apply duration-100 ease-linear;
  }
  .hover-scale-sm:hover {
    @apply opacity-85;
    transform: scale(0.99);
  }
  .hover-scale-md {
    @apply duration-100 ease-linear;
  }
  .hover-scale-md:hover {
    @apply opacity-75;
    transform: scale(0.98);
  }
  .hover-scale-110 {
    @apply duration-200 ease-linear;
  }
  .hover-scale-110:hover {
    @apply scale-110;
  }
  .hover-translate-y {
    @apply duration-200 ease-linear;
  }
  .hover-translate-y:hover {
    @apply translate-y-[-3px];
  }

  /* Position */
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform;
  }

  /* Component */
  /* input */
  .cms-input {
    /* @apply border-lightgrey w-full rounded-md border placeholder-[#DCDFE6] focus:border-[#cac9c9] focus:outline-none focus:ring-0; */
    @apply shadow-floating-label text-dark h-12 w-full cursor-pointer appearance-none rounded-lg border-0 bg-white p-3 text-sm font-semibold focus:border focus:border-secondary focus:outline-none focus:ring-0;
  }

  /* badge */
  .badge-base {
    @apply hover-scale-sm flex h-7 w-fit min-w-fit cursor-pointer items-center justify-center text-nowrap rounded-[6px] p-3 text-xs font-medium;
  }
  .badge-purple {
    @apply badge-base bg-[#8338EC26] text-[#8338EC];
  }
  .badge-red {
    @apply badge-base bg-[#F23A5A26]  text-[#F23A5A];
  }
  .badge-pending {
    @apply badge-base bg-[#FFB3000D]  text-[#FFB300];
  }
  .badge-approved {
    @apply badge-base bg-[#24D46D0D]  text-[#24D46D];
  }
  .badge-processing {
    @apply badge-base bg-[#9D65220D]  text-[#9D6522];
  }
  .badge-waiting-for-approval {
    @apply badge-base bg-[#9D65220D]  text-[#9D6522];
  }
  .badge-rejected {
    @apply badge-base bg-[#EE20200D]  text-[#EE2020];
  }
  .badge-completed {
    @apply badge-base bg-[#0306230D]  text-[#030623];
  }
}

:root {
  overflow-x: hidden;
}

/* Animations */
@keyframes rotateAnimation {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}
.animate-rotate-right {
  animation: rotateAnimation 5s linear infinite;
}

@keyframes moveUpDownAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-move-up-down {
  animation: moveUpDownAnimation 2s linear infinite;
}

@keyframes fadeInDownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-down {
  animation: fadeInDownAnimation 0.2s linear;
}

@keyframes fadeOutUpAnimation {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20);
  }
}

.animate-fade-out-up {
  animation: fadeOutUpAnimation 0.2s linear;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 16px;
  height: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 1 0 2px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: grey;
}
