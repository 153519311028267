.custom-calendar {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 6px 24px -6px rgba(3, 6, 35, 0.1);
  border: none;
  font-family: "DM Sans", sans-serif;
  color: #030623;
  font-size: 18px;
  font-weight: 500;
  z-index: 20;
}

.custom-day {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50px;
  height: 44px;
  padding: 13px 20px;
  border-radius: 6px;
  margin: 0.25rem;
  color: #030623;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
  background-color: #fff;
  box-shadow: 0px 6px 24px 0px rgba(3, 6, 35, 0.1);
}

.custom-day:hover {
  background-color: #16e6ed1a;
}

.custom-day.react-datepicker__day--selected {
  background-color: #16e6ed;
  color: white;
}

.custom-day.react-datepicker__day--today {
  border: 2px solid #16e6ed;
}

.custom-day.react-datepicker__day--outside-month {
  color: #71707126;
  box-shadow: none;
}

.custom-popper {
  border-radius: 12px;
}

.react-datepicker__header.react-datepicker__header--custom {
  background-color: #fff;
  border: none;
}

.react-datepicker__day-name {
  width: 54px;
  height: 44px;
  line-height: 2.7rem;
}

/* Year Month Picker */

.custom-year-month-calendar {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 6px 24px -6px rgba(3, 6, 35, 0.1);
  border: none;
  font-family: "DM Sans", sans-serif;
  color: #45454a;
  font-size: 15px;
  font-weight: 500;
  z-index: 20;
  margin-top: 6px;
}

.react-datepicker__month-container {
  padding: 12px;
}

.react-datepicker__header.react-datepicker-year-header {
  background-color: #fff;
  border: none;
  width: 100%;
  height: 44px;
  font-size: 15px;
  font-weight: 500;
}

.react-datepicker__month.react-datepicker__monthPicker {
  gap: 6px;
  display: flex;
  flex-direction: column;
}

.react-datepicker__month .react-datepicker__month-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 40px;
  border-radius: 6px;
  color: #030623;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
  background-color: #fff;
  box-shadow: 0px 6px 24px 0px rgba(3, 6, 35, 0.1);
}
.react-datepicker__month .react-datepicker__month-text:hover {
  background-color: #16e6ed1a;
}
.react-datepicker__month .react-datepicker__month-text--selected {
  background-color: #16e6ed;
  color: white;
}
.react-datepicker__month .react-datepicker__month-text--today {
  font-weight: 500;
}
.react-datepicker__month .react-datepicker__month-text--outside-month {
  color: #71707126;
  box-shadow: none;
}

.react-datepicker__month-wrapper {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.react-datepicker__navigation-icon{
  top:0;
  width: auto;
  font-size: 16px;
  font-weight: 100;
}

.react-datepicker__navigation.react-datepicker__navigation--next{
  margin-top: 12px;
  margin-right: 14px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 6px 24px rgba(3, 6, 35, 0.10);
}
.react-datepicker__navigation-icon--next{
  left:0
}

.react-datepicker__navigation.react-datepicker__navigation--previous{
  margin-top: 12px;
  margin-left: 14px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 6px 24px rgba(3, 6, 35, 0.10);
}
.react-datepicker__navigation-icon--previous{
  right:0
}